import { createApp } from 'vue'
import App from './App.vue'
import Router from './router'
import Store from './store'

import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import Tooltip from 'primevue/tooltip'
import './assets/css/style.css'

const app = createApp(App)
app.use(Store)
app.use(Router)
app.use(PrimeVue)
app.directive('tooltip', Tooltip)

app.mount('#app')