import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import VerzeichnisBruder from '../views/VerzeichnisBruder.vue'
import Bruder from '../views/Bruder.vue'
import DocList from '../views/DocList.vue'
import DocDisplay from '../views/DocDisplay.vue'
//import DocDisplay from '../views/DocDisplay.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/verzeichnis',
    name: 'VerzeichnisBruder',
    component: VerzeichnisBruder,
  },
  {
    path: '/verzeichnis/:bruder',
    name: 'Bruder',
    component: Bruder,
  },
  {
    path: '/doc',
    name: 'DocList',
    component: DocList,
  },
  {
    path: '/doc/:postId',
    name: 'DocDisplay',
    component: DocDisplay,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
})

export default router
