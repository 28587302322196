<template>
  <div class="post paddingTopPage">
    <ToolbarTop url="/doc" :backLabel="Dokumente" :title="Titolo"></ToolbarTop>

    <div v-if="this.pdfSource == null" class="centre-img"><img src="/img/wheel.gif"></div>
    <div v-else class="article mb100">
      <div class="centre" v-html="title"></div>
      <div class="pdfEntete">
        <span v-if="showAllPages">{{ pageCount }} Seite(n)</span>
        <span v-else>
          <button :disabled="page <= 1" @click="page--">❮</button>
          {{ page }} / {{ pageCount }}
          <button :disabled="page >= pageCount" @click="page++">❯</button>
        </span>

        <label class="right">
          <input v-model="showAllPages" type="checkbox">
          Alle Seiten zeigen
        </label>
  
      </div>
      <VuePdfEmbed annotation-layer ref="pdfRef" :page="page" @loaded="handleDocumentLoad" :source="pdfSource" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToolbarTop from '../views/ToolbarTop.vue';
import VuePdfEmbed from 'vue-pdf-embed'
import 'vue-pdf-embed/dist/style/index.css'
import 'vue-pdf-embed/dist/style/annotationLayer.css'
import 'vue-pdf-embed/dist/style/textLayer.css'

export default {
  name: "doc-display",
  components: { VuePdfEmbed, ToolbarTop },
	data() {
		return {
      linguaCorrente: null,
      Dokumente: "Dokumente",
      title: "",
      page: null,
      pdfSource: null,
      pageCount: null,
      showAllPages: true,
    }
	},
  computed: {
    ...mapState(['docsId', 'docs', 'docsLoaded']),
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1
    },
    docs(newValue) {
      this.pdfSource = newValue;
      this.title = this.$store.state.docsTitle;
    },
  },
  methods: {
    handleDocumentLoad({ numPages }) {
      console.log('document is loaded')
      this.pageCount = numPages
    },
  },
  beforeCreate() {
    // verify that the user is logged
    this.localName     = localStorage.getItem("nameField");
    if ( this.localName == null ) {
      this.$router.push({ path: '/', query: { callback: this.$router.currentRoute._value.fullPath } })
    }
  },
  created() {
    console.log('created, params.postID 2 = ', this.$route.params.postId)
    // Load the doc
    if ( this.$store.state.docsId == null ) { this.$store.commit( 'SET_DOCS_ID', this.$route.params.postId ) }
    this.$store.dispatch("getDocs");
  }
} 
</script>
