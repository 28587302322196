<template>
  <div class="paddingTopPage60 centre" v-if="this.frateTrovato">
    <ToolbarTop url="/verzeichnis" backLabel="Verzeichnis"></ToolbarTop>
    <div class="frameBruder">
      <div>
        <img :src="foto" width="100" />
      </div>
      <div class="nomeCognome">{{ this.vorname }} {{ this.name }}</div>
      <div class="center gras ital" v-if="this.funktion">{{ this.funktion }}</div>
      <div class="cardYellow mt15">
        <div class="testoNormale"><strong>Geboren am </strong>{{ this.geburtstag }} <span>({{ this.eta }})</span></div>
        <div class="testoNormale mb10"><strong>Namenstag:</strong>&nbsp;&nbsp;{{ this.namenstag }}</div>
        <div class="testoNormale" v-if="this.zeitprofess"><strong>Zeitliche Profess:</strong>&nbsp;&nbsp;{{ this.zeitprofess }} <span>({{ this.zeitprofessEta }})</span></div>
        <div class="testoNormale" v-if="this.feierprofess"><strong>Feierliche Profess:</strong>&nbsp;&nbsp;{{ this.feierprofess }} <span>({{ this.feierprofessEta }})</span></div>
        <div class="testoNormale" v-if="this.priesterweihe"><strong>Priesterweihe:</strong>&nbsp;&nbsp;{{ this.priesterweihe }} <span>({{ this.priesterweiheEta }})</span></div>
      </div>
      <div class="cardWhite borderBottom">
        <div class="testoNormale"><strong>E-mail:</strong>&nbsp;&nbsp;<a :href="emailAdr" target="new">{{ this.emailadresse }}</a></div>
        <div class="testoNormale"><strong>Mobiltelefon:</strong>&nbsp;&nbsp;<a :href="numTel" target="new">{{ this.telefon }}</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolbarTop from './ToolbarTop.vue';
import { mapState } from 'vuex';
//import axios from 'axios'

export default {
  name: 'DerBruder',
  components: { ToolbarTop },
  data() {
    return {
      frateTrovato: false,
      name: null,
      vorname: null,
      nomeCognome: null,
      funktion: null,
      telefon: null,
      emailadresse: null,
      geburtstag: null,
      namenstag: null,
      email: null,
      zeitprofess: null,
      feierprofess: null,
      priesterweihe: null,
      konvent: null,
      status: null,
      foto: null,
      mesi: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      imgUrl: "/wordpress/wp-content/uploads/images/bruder/",
      eta: 0,
      zeitprofessEta: 0,
      feierprofessEta: 0,
      priesterweiheEta: 0
    }
  },
  computed: {
    emailAdr() {
      return "mailto:" + this.emailadresse;
    },
    numTel() {
      return "tel:" + this.telefon;
    },
    ...mapState(['frati', 'fratiOk']),
  },
  watch: {
    frati() {
      this.searchFrate();
    },
  },
  methods: {
    searchFrate() {
//      for (const [key, frate] of Object.entries(this.$store.state.frati)) {
      for (const frate of this.$store.state.frati) {
        this.nomeCognome = frate.name.trim() + "-" + frate.vorname.trim();
        if ( this.nomeCognome == this.$route.params.bruder ) {
          console.log(frate);
          this.name = frate.name;
          this.vorname = frate.vorname;
          this.funktion = frate.funktion;
          this.telefon = frate.telefon;
          this.emailadresse = frate.emailadresse;
          
          this.eta = this.calcoloAnni(frate.geburtstag);
          let geburtstag_temp = frate.geburtstag.substr(0, 1) == "0" ? frate.geburtstag.substr(1, 1) : frate.geburtstag.substr(0, 2);
          this.geburtstag = geburtstag_temp + ". " + this.mesi[parseInt(frate.geburtstag.substr(3, 2)) - 1] + " " + frate.geburtstag.substr(6, 4);

          let namenstag_temp = frate.namenstag.substr(0, 1) == "0" ? frate.namenstag.substr(1, 1) : frate.namenstag.substr(0, 2);
          this.namenstag = namenstag_temp + ". " + this.mesi[parseInt(frate.namenstag.substr(3, 2)) - 1];

          if (frate.zeitprofess) {
            this.zeitprofessEta = this.calcoloAnni(frate.zeitprofess);
            let zeitprofess_temp = frate.zeitprofess.substr(0, 1) == "0" ? frate.zeitprofess.substr(1, 1) : frate.zeitprofess.substr(0, 2);
            this.zeitprofess = zeitprofess_temp + ". " + this.mesi[parseInt(frate.zeitprofess.substr(3, 2)) - 1] + " " + frate.zeitprofess.substr(6, 4);
          }

          if (frate.feierprofess) {
            this.feierprofessEta = this.calcoloAnni(frate.feierprofess);
            let feierprofess_temp = frate.feierprofess.substr(0, 1) == "0" ? frate.feierprofess.substr(1, 1) : frate.feierprofess.substr(0, 2);
            this.feierprofess = feierprofess_temp + ". " + this.mesi[parseInt(frate.feierprofess.substr(3, 2)) - 1] + " " + frate.feierprofess.substr(6, 4);
          }

          if (frate.priesterweihe) {
            this.priesterweiheEta = this.calcoloAnni(frate.priesterweihe);
            let priesterweihe_temp = frate.priesterweihe.substr(0, 1) == "0" ? frate.priesterweihe.substr(1, 1) : frate.priesterweihe.substr(0, 2);
            this.priesterweihe = priesterweihe_temp + ". " + this.mesi[parseInt(frate.priesterweihe.substr(3, 2)) - 1] + " " + frate.priesterweihe.substr(6, 4);
          }

          this.konvent = frate.konvent;
          this.status = frate.status;
          this.frateTrovato = true;
          console.log('foto = ', frate.foto);
          this.foto = this.$store.state.rootUrl + this.$store.state.imgUrl + frate.foto;
        }
      }
    },
    calcoloAnni(birthday) {
      var bd = birthday.split(".");
      console.log("birthday = ", birthday);
      var newBirthday = new Date(
        parseInt(bd[2]),
        parseInt(bd[1]) - 1,
        parseInt(bd[0])
      );
      var ageDifMs = Date.now() - newBirthday.getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
  },  
  beforeCreate() {
    // verify that the user is logged
    this.localName     = localStorage.getItem("nameField");
    if ( this.localName == null ) {
      //console.log('der bruder = ', this.$router.currentRoute._value)
      this.$router.push({ path: '/', query: { callback: this.$router.currentRoute._value.fullPath } })
    }
  },
  created() {
    // Load Frati
    if ( !this.$store.state.fratiOk ) {
      this.$store.dispatch("getFrati"); }
    else {
      this.searchFrate();
    }
  },
} 
</script>

