<template>
  <div>
    <div id="nav"></div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex';
//import $ from 'jquery';

export default {
  name: 'App',
  data() {
    return {
      a: false,
      niveau: 0,
      menuJSON: "",
      level: -1,
      levelSave: [],
      aucunArticle: false,
      pdf: false,
      classRootMenu: "",
    }
  },
  computed: {
    ...mapState( ['postsList', 'categoriesList', 'paramsOk'] )
  },
  mounted() {
    // Load the parameters
    console.log('the mounted')
    if ( !this.$store.state.paramsOk ) {
      console.log('mounted')
      this.$store.dispatch("getParams");
    }
    // read the Wordpress Categories if not already loaded
    if (this.$store.state.postsList.length == 0) {
      console.log(' passa par là ')
      this.$store.dispatch("getCatPostList");
    }
  },
  // *** WATCH ***
  watch: {
    postsList() {
      // Construit LE MENU GENERAL

      this.categoriesList.sort((a, b) => a.slug.localeCompare(b.slug));

//      let mainParentID = this.$store.state.categoriesList.filter(obj => { return obj.slug == '00-root-' + this.linguaCorrente.toLowerCase() });
//      let theID = mainParentID[0].id;

      this.menuJSON = '[';
      // les catégories à la racine
      var parentIDSList = this.$store.state.categoriesList.filter(obj => { return obj.parent == 0 });
      console.log('parentIDSList = ', parentIDSList);
      // var parentPrecedent = parentIDSList[0];
      this.createMenu(parentIDSList, '0');
      this.menuJSON += ']';

      console.log('menuJSON = ', this.menuJSON)
      var convertedJSON = JSON.parse(this.menuJSON)
      console.log('convertedJSON = ', convertedJSON)
      this.$store.commit('SET_DOCS_JSON', convertedJSON);
      console.log('chaine finale = ', this.$store.state.docsJSON)
    },
  },

  methods: {
    createMenu(parentIDSList, level) {
      this.niveau++;
      var virguleMain = "";
      parentIDSList.forEach((element, index) => {
        console.log('index = ', index, ' level = ', level, ' niveau = ', this.niveau);
        console.log('element = ', element);
        if (this.niveau == 1) {
          level = index.toString();
        } else {
          level = this.levelSave[this.niveau - 1] + '-' + index.toString();
        }

        this.menuJSON += virguleMain + '{ "key": "' + level + '",';
        this.menuJSON += '"label": "' + parentIDSList[index].name + '",';
        this.menuJSON += '"data": "NODE",';
        this.menuJSON += '"styleClass": "' + parentIDSList[index].description + '",'; 
        this.menuJSON += '"icon": "pi pi-fw pi-folder"';

        let newParentIDSList = this.$store.state.categoriesList.filter(obj => { return obj.parent == element.id });
        // console.log('newParentIDSList = ', newParentIDSList)
        var children = false;
        console.log('newParentIDSList = ', newParentIDSList)
        if (newParentIDSList.length > 0) { // S'il y a des children
          children = true;
          // var levelDisplay = level.toString();
          // console.log('level = ', level, 'levelDisplay = ', levelDisplay, ' index = ', index)
          this.menuJSON += ', "children": [';

          this.levelSave[this.niveau] = level;
          console.log('il y a des children and le level = ', level)
          this.createMenu(newParentIDSList, level);


        }
        if (children) {
          this.menuJSON += ' ]'; }
        else {
          //        if ( this.niveau > 1 ) {
          // console.log('id à rechercher = ', element.id, " - ", element.name)
          this.searchArticle(element.id, level);
          if (this.aucunArticle) { // Si aucun article a été trouvé on insère un enfant "No documents"
            this.menuJSON += ', "children": [';
            this.menuJSON += ' { "key": "' + level + '-0",';
            this.menuJSON += '"label": "Keine Dokumente",';
            this.menuJSON += '"data": "NODOCS",';
            this.menuJSON += '"icon": "pi pi-fw pi-ban" }';
            this.menuJSON += ' ]';
            this.aucunArticle = false;
          }
        }

        this.menuJSON += ' } ';
        virguleMain = ', ';

      })

      this.niveau--;
    },

    searchArticle(id, level) {

      var virguleFiles = "";
      var first = true;
      var childrenDisplayed = false;
      var index = 0;

      this.aucunArticle = false;
      this.$store.state.postsList.forEach((element) => {

        if (element.categories.includes(id)) {
          if (first) {
            this.menuJSON += ', "children": [';
            first = false;
            childrenDisplayed = true;
          }

          this.menuJSON += virguleFiles + ' { "key": "' + level + '-' + index + '",';
          this.menuJSON += '"label": "' + decodeHtml(element.title.rendered) + '",'; // eslint-disable-line
          this.menuJSON += '"data": "' + element.id + '",';
          this.menuJSON += '"icon": "pi pi-fw pi-angle-right" }';
          virguleFiles = ', ';
          index++;
        }
      })

      if (index == 0) {
        this.aucunArticle = true;
      }

      if (childrenDisplayed) {
        this.menuJSON += ' ]';
      }
    }
  }
}
</script>
