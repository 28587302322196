<template>
  <div class="paddingTopPage centre" v-if="this.$store.state.paramsOk">
    <div>
      <div>
        <div><img src="@/assets/img/logo200.png" width="150"></div>
      </div>
      <div class="bordeaux titre-home" v-html="this.$store.state.params.appNameDE"></div>
      <!--<div class="font20 bold" v-html="this.$store.state.params.appNameFR">
      </div> -->

      <!-- *************
           *** LOGIN ***
           ************* -->
      <div class="loginBox notActive" :class="{ active: this.$store.state.loginIsActive }">
        <div>
          <div class="mb3 font20">Benutzername</div>
          <InputText @keyup.enter="verifyCredentials(localName, localPassword)" 
                     class="inputField" v-model="localName" type="text" id="nameField" />
          <div class="mt3 displayErrorOff" :class="{ displayError: errorName }">Feld ist erforderlich</div>
        </div>
        <div>
          <div class="mt10 mb3 font20">Passwort</div>
          <Password @keyup.enter="verifyCredentials(localName, localPassword)" 
                    class="inputField" v-model="localPassword" :feedback="false" toggleMask  id="passField" />
          <div class="mt3 displayErrorOff" :class="{ displayError: errorPass }">Feld ist erforderlich</div>
        </div>
        <Button @click="verifyCredentials(localName, localPassword)" class="mt20" label="Einloggen" severity="success" raised rounded />
      </div>

      <!-- ********************
           *** ERROR DIALOG ***
           ******************** -->
      <Dialog v-model:visible="this.$store.state.passError" 
              modal
              :showHeader=false
              :closable=false
              header="Login Error" 
              :style="{ width: '300px' }">
          <div v-if="this.$store.state.loginResult == 'errorname'" class="mt40 font20 bold">Ungültiger Benutzername</div>
          <div v-if="this.$store.state.loginResult == 'wrongpassword'" class="mt40 font20 bold">Falsches Passwort</div>
          <div class="flex flex-right">
              <Button type="button" class="mt20" label="Schliessen" severity="secondary" @click="this.$store.state.passError = false;"></Button>
          </div>
      </Dialog>

      <!-- ************
           *** MENU ***
           ************ -->
      <div class="menu-container font20 notActive" :class="{ active: !this.$store.state.loginIsActive }">
        <div>
          <router-link to="/verzeichnis">
            {{ this.$store.state.params.annuarioFrati }}
          </router-link>
        </div>
        <div>
          <router-link to="/doc">
            {{ this.$store.state.params.documenti }}
          </router-link> 
        </div>
        <div class="mt10">
          <a :href="this.$store.state.rootUrl + '/wordpress'">Admin</a> 
        </div>
        <div>
          <router-link to="/" @click="logout()">
            Logout
          </router-link> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Password from 'primevue/password';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import $ from 'jquery';

export default {
  name: 'HomePage',
  components: { Password, InputText, Button, Dialog },
  data() {
    return {
      value: "",
      localName: null,
      localPassword: null,
      errorName: false,
      errorPass: false,
    }
  },
  created() {
    console.log('query = ', this.$route.query.callback )
    this.$store.commit( 'SET_LOGIN_CALLBACK', this.$route.query.callback )

    this.localName     = localStorage.getItem("nameField");
    this.localPassword = localStorage.getItem("passField");
    if ( this.localName != null ) {
      this.$store.commit( 'SET_NAME_FIELD', this.localName )
      this.$store.commit( 'SET_PASS_FIELD', this.localPassword )
      this.$store.commit( 'SET_LOGIN_IS_ACTIVE', false )
    }
    else {
      this.$store.commit( 'SET_LOGIN_IS_ACTIVE', true )
    }
    console.log('localName = ', this.localName);
    console.log('localPassword = ', this.localPassword);

  },
  computed: {
    ...mapState(['paramsOk', 'params']),
  },
  methods: {
    // FOCUSINPUT
    focusInput() {
      console.log('salutsalut')
      if ( this.$store.state.loginResult == 'errorname' ) {
        var id = 'nameField';
        var input = '' 
      }
      if ( this.$store.state.loginResult == 'wrongpassword' ) {
        id = 'passField'; 
        input = ' input';
      }
      console.log('id et input ', id+input)
      $( '#' + id + input ).focus();
    },
    // VERUFYCREDENTIALS
    verifyCredentials(user, pass)  {

      // *** verify presence of username
      if ( user == null ) {
        this.errorName = true; }
      else {
        if ( user.trim() == "" ) {
          this.errorName = true; } 
        else {
          this.errorName = false;
        }
      }

      // *** verify presence of password
      if ( pass == null ) {
        this.errorPass = true; }
      else {
        if ( pass.trim() == "" ) {
          this.errorPass = true; } 
        else {
          this.errorPass = false;
        }
      }

      // focus on username or password if not present
      if ( this.errorName ) {
        this.focusInput('nameField'); }
      else if ( this.errorPass ) {
        this.focusInput('passField'); }
      else {
        // otherwise php control of user and pass 
        this.$store.commit( 'SET_NAME_FIELD', user );
        this.$store.commit( 'SET_PASS_FIELD', pass );   
        this.$store.dispatch( "verifyCredentials");
      }
      console.log('errorPass = ', this.errorPass )
    },

    logout() {
      localStorage.removeItem( "nameField" );
      localStorage.removeItem( "passField" );
      this.localName     = "";
      this.localPassword = "";

      this.$store.commit( 'SET_NAME_FIELD', "" )
      this.$store.commit( 'SET_PASS_FIELD', "" )
      this.$store.commit( 'SET_LOGIN_IS_ACTIVE', true )
    }
  }
}
</script>

<style scoped>

.loginBox { width: 300px; margin: auto; padding: 20px 0; }
.notActive { display: none; }
.active { display: block; }
.inputField, .inputField :deep(input) { width: 300px !important; }
.p-button { background-color:#10b981 !important; }
.p-button:hover { background-color: #0e9d6e !important; }

.displayError { visibility: visible !important; }
.displayErrorOff { color: red; visibility: hidden; }
</style>