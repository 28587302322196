<template>
  <div class="paddingTopPage centre" v-if="postsLoaded">
    <ToolbarTop url="/" backLabel="Home"></ToolbarTop>
    <div class="docListWrapper">
      <div class="title">{{ this.$store.state.params.documenti }}</div>

      <div class="dropdownHome">
        <div id="buttonBlock" class="extCollButton">
            <Button type="button" icon="pi pi-plus" @click="expandAll(this.$store.state.docsJSON)" v-tooltip.top="{ value: expAll }" class="p-button-text" />&nbsp;
            <Button type="button" icon="pi pi-minus" @click="collapseAll" v-tooltip.top="{ value: collAll }" class="p-button-text" />&nbsp;
            <Button type="button" icon="pi pi-search" @click="displaySearchBox" v-tooltip.top="{ value: displaySearch }" class="p-button-text" />
        </div>
      </div>

      <div v-if="Object.keys(this.$store.state.docsJSON).length === 0"><img src="/img/wheel.gif"></div>
      <div v-else>
        <Tree selectionMode="single" :value="this.$store.state.docsJSON" :filter="true" filterMode="lenient"
            @nodeSelect="onNodeSelect" @nodeUnselect="onNodeUnselect" :expandedKeys="expandedKeys"
            @nodeExpand="espandiNodo">
        </Tree>
      </div>
      <!--<div class="title">WORK IN PROGRESS</div>
      <div style="margin-top: 30px;"><img src="@/assets/img/work-in-progress.gif"></div> -->
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ToolbarTop from '../views/ToolbarTop.vue';
import Tree from 'primevue/tree';
import Button from 'primevue/button';
import $ from 'jquery';

export default {
  props: { url: String, backLabel: String },
  name: "DocList",
  components: { ToolbarTop, Tree, Button },
	data() {
		return {
      titolo: "",
      expandedKeys: {},
    }
	},
  computed: {
    collAll() { return "collapse all"; },
    expAll() { return "alle expandieren"; },
    displaySearch() { return "Search"; },
    ...mapState(['postsLoaded', 'paramOpen']),
  },
  methods: {
    expandAll(theNodes) {
        for (let node of theNodes) {
            this.expandNode(node, true);
        }
        this.expandedKeys = {...this.expandedKeys};
        this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
        $('.p-inputtext').focus();
    },
    collapseAll() {
      this.expandedKeys = {};
      this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
      $('.p-inputtext').focus();
    },
    displaySearchBox() {
      var dispSearch = $('.p-tree-filter-container').css('display');
      console.log( 'display = ', dispSearch)
      if ( dispSearch === 'none' ) {
        $('.p-tree-filter-container').attr('style', 'display: block !important'); 
        $('.p-inputtext').focus(); }
      else {
        $('.p-tree-filter-container').attr('style', 'display: none !important');
      }
    },
    collapseNode( node ) {
        this.expandedKeys[node.key] = false;
        this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
    },
    espandiNodo( node ) {
      console.log('espandiNodo...', node.key);
    },
    expandNode( node, childrenAlso ) {
      console.log("j'expande le node");
      if (node.children && node.children.length) {
        if ( !this.expandedKeys[node.key] ) {
          this.expandedKeys[node.key] = true; 
          this.$store.commit( 'SET_EXPANDED_KEYS_SAVE', this.expandedKeys )
        }
        if ( childrenAlso ) {
          this.expandAll(node.children);
        }
      }
    },
    onNodeSelect(node) {
      console.log('node = ', node)
      console.log( 'this.expandedKeys = ', this.expandedKeys)
      if ( this.expandedKeys[node.key] ) {
        this.collapseNode(node);
        //console.log('déjà expanded');
        return;
      }
      if ( node.data == 'NODE' ) {
        //console.log("c'est un node");
        this.expandNode(node, false); }
      else {
        if ( node.data != 'NODOCS' ) {
          console.log('postId = ', node.data);
          this.$store.commit( 'SET_DOCS_ID', node.data )
          this.$router.push({ name: 'DocDisplay', params: { postId: node.data } });
          localStorage.lastPost = node.data;
        }
      }
    },
    onNodeUnselect(node) {
        this.collapseNode(node);
    },
  },
  watch: {
    docsOk () {
      console.log('lista documenti = ', this.$store.state.docs )
    },
    paramsOk() {
      this.titolo = this.$store.state.params.menu.documenti
    }
  },
  beforeCreate() {
    // verify that the user is logged
    this.localName     = localStorage.getItem("nameField");
    if ( this.localName == null ) {
      this.$router.push({ path: '/', query: { callback: this.$router.currentRoute._value.fullPath } })
    }
  },
} 
</script>
