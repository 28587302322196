<template>
  <div class="paddingTopPage centre">
    <ToolbarTop url="/" backLabel="Home"></ToolbarTop>
    <div v-if="!this.$store.state.fratiOk || !this.$store.state.paramsOk">
      <img src="@/assets/img/wheel.gif">
    </div>
    <div class="mb20" v-if="this.$store.state.fratiOk && this.$store.state.paramsOk">
      <div class="title" v-html="this.$store.state.params.annuarioFrati"></div>
      <div v-for="(item, index) in this.$store.state.frati" :key="index" >
        
        <div v-if="item.status != 'Q'">
          <div class="card flex align-items-center line-height-0">
            <router-link :to="{ name: 'Bruder',  params: { bruder: nameAndSurname(item.name, item.vorname) } }">
              <div><img :src="foto(item.foto)" width="75" height="75" /></div>
            </router-link>
            <div class="cardTextBlock flex justify-content-left flex-direction-column">

              <router-link class="font20 jauneclair bold" :to="{ name: 'Bruder',  params: { bruder: nameAndSurname(item.name, item.vorname) } }">
                <div>{{ item.vorname }} {{ item.name.toUpperCase() }}</div>
              </router-link>

              <a class="testoNormale blanc" :href="emailAdr(item.emailadresse)" target="new">{{ item.emailadresse }}</a>
              <a class="testoNormale blanc" :href="numTel(item.telefon)" target="new">{{ item.telefon }}</a>
            </div>
            <!--<span class="cardFunzione" v-if="item[0].funzione"> ({{ item[0].funzione }})</span>-->
          </div>
        </div>
          
      </div>
    </div>
  </div>
</template>

<script>
import ToolbarTop from './ToolbarTop.vue';
import { mapState } from 'vuex';

export default {
  name: 'VerzeichnisBruder',
  components: { ToolbarTop },
  data() {
    return {
      titolo: "",
      localName: "",
    }
  },
  computed: {
    ...mapState(['frati', 'fratiOk', 'params', 'paramsOk']),
  },
  watch: {
    paramsOk() {
      this.titolo = this.$store.state.params.appNameDE
      console.log('titolo = ', this.titlo )
    },
    fratiOk() {
      console.log('fratiok...')
      this.$store.state.frati.sort(function (a, b) {
        if ( a.vorname < b.vorname ) { return -1; }
        if ( a.vorname > b.vorname ) { return 1; }
        return 0;
      })
    }
  },
  beforeCreate() {
    // verify that the user is logged
    this.localName     = localStorage.getItem("nameField");
    if ( this.localName == null ) {
      this.$router.push({ path: '/', query: { callback: 'verzeichnis' } })
    }
  },
  created() {
    // Load params
    if ( !this.$store.state.paramsOk ) {
      this.$store.dispatch("getParams"); }
    else {
      this.titolo = this.$store.state.params.appNameDE
    }
    // Load Frati
    if ( !this.$store.state.fratiOk ) {
      this.$store.dispatch("getFrati");
    }
  },
  methods: {
    nameAndSurname(nom, prenom) {
      return nom.trim() + "-" + prenom.trim();
    },
    foto(fratefoto) {
      return this.$store.state.rootUrl + this.$store.state.imgUrl + fratefoto;
    },
    emailAdr(email) {
      return "mailto:" + email;
    },
    numTel(tel) {
      return "tel:" + tel;
    }
  }
} 
</script>